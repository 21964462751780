<template>
  <CBox bg="main" h="54px">
    <!-- <Cbox ml="60px" mr="60px" pt="5px" pl="10px" pr="10px" pb="5px"> -->
    <Loading :active="isLoading" />
    <CFlex
      pt="5px"
      pb="5px"
      alignItems="center"
      justifyContent="space-between"
      maxW="1200px"
      mx="auto"
      :d="['none', 'none', 'none', 'flex']"
    >
      <CFlex>
        <a
          v-if="isAuthenticated"
          href="https://gethired.id/employee/welcome"
          style="vertical-align: middle"
        >
          <img src="@/assets/img/logo.svg" />
        </a>
        <a v-else href="https://gethired.id" style="vertical-align: middle">
          <img src="@/assets/img/logo.svg" />
        </a>
      </CFlex>

      <CStack display="flex" direction="row" :spacing="6">
        <CButton
          bg="bgCodeCompetitionBtn"
          color="white"
          :_hover="{ bg: 'bgCodeCompetitionBtn' }"
          as="a"
          :href="blogUrl"
        >
          <CImage :src="require('@/assets/icon/ic-devcode.png')" pr="5px" />
          DEVCODE
        </CButton>
        <CButton
          bg="orange"
          color="white"
          :_hover="{ bg: 'bgCodeCompetitionBtn' }"
          as="a"
          :href="`${urlGethired}/jobs`"
        >
          CARI KERJA
        </CButton>
        <c-menu>
          <c-menu-button
            bg="main"
            :_hover="{ bg: 'main' }"
            border-width="0px"
            boxShadow="0px"
            :_focus="{
              outline: 'none',
              boxShadow: 'none',
            }"
            right-icon="chevron-down"
            color="white"
          >
            <CAvatar
              w="35px"
              h="35px"
              mr="5px"
              :name="currentUser.shortName"
              :src="cdnGethiredUrl + '/' + currentUser.emp_photo"
            ></CAvatar>
            <CText fontWeight="bold">Hi, {{ currentUser.shortName }}</CText>
          </c-menu-button>
          <c-menu-list zIndex="99">
            <c-menu-item as="a" :href="feURL + '/employee/profile'"
              >Lihat Profile</c-menu-item
            >
            <c-menu-item as="a" :href="feURL + '/employee/setting'"
              >Ubah Password</c-menu-item
            >
            <c-menu-item as="a" :href="feURL + '/employee/cv'"
              >Pilih Desain CV</c-menu-item
            >
            <c-menu-item as="a" :href="feURL + '/employee/application'"
              >Lamaran Saya</c-menu-item
            >
            <c-menu-item as="a" href="/clubs">Clubs</c-menu-item>
            <c-menu-item as="a" href="/track-career-all">Track</c-menu-item>
            <!-- <c-menu-item @click="$router.push({ name: 'Dashboard Overview' })"
              >Lihat Web CV</c-menu-item
            > -->
            <c-menu-item as="a" :href="feURL + '/logout'" @click="logout()"
              >Keluar</c-menu-item
            >
          </c-menu-list>
        </c-menu>
      </CStack>
    </CFlex>

    <!-- mobile -->
    <CFlex
      pt="5px"
      pb="5px"
      alignItems="center"
      justifyContent="space-between"
      maxW="1080px"
      mx="auto"
      :d="['flex', 'flex', 'flex', 'none']"
    >
      <CFlex>
        <a
          v-if="isAuthenticated"
          href="https://gethired.id/employee/welcome"
          style="vertical-align: middle"
        >
          <CImage
            :src="require('@/assets/img/logo.svg')"
            :w="['100px', '150px', '170px', '100%']"
            :ml="['1em', '1em', '1em', '0']"
          />
        </a>
        <a v-else href="https://gethired.id" style="vertical-align: middle">
          <CImage
            :src="require('@/assets/img/logo.svg')"
            :w="['100px', '150px', '170px', '100%']"
            :ml="['1em', '1em', '1em', '0']"
          />
        </a>
      </CFlex>
      <c-menu>
        <c-menu-button
          bg="main"
          :_hover="{ bg: 'main' }"
          border-width="0px"
          boxShadow="0px"
          :_focus="{
            outline: 'none',
            boxShadow: 'none',
          }"
          right-icon="chevron-down"
          color="white"
        >
          <CAvatar
            w="35px"
            h="35px"
            mr="5px"
            :name="currentUser.shortName"
            :src="cdnGethiredUrl + '/' + currentUser.emp_photo"
          />
          <CText fontWeight="bold">Hi, {{ currentUser.shortName }}</CText>
        </c-menu-button>
        <c-menu-list zIndex="99">
          <c-menu-item as="a" :href="feURL + '/employee/profile'"
            >Lihat Profile</c-menu-item
          >
          <c-menu-item as="a" :href="feURL + '/employee/setting'"
            >Ubah Password</c-menu-item
          >
          <c-menu-item as="a" :href="feURL + '/employee/cv'"
            >Pilih Desain CV</c-menu-item
          >
          <c-menu-item as="a" :href="feURL + '/employee/setting'"
            >Cari Kerja</c-menu-item
          >
          <c-menu-item as="a" :href="feURL + '/employee/application'"
            >Lamaran Saya</c-menu-item
          >
          <c-menu-item as="a" href="/clubs">Clubs</c-menu-item>
          <c-menu-item as="a" href="/track-career-all">Track</c-menu-item>
          <!-- <c-menu-item @click="$router.push({ name: 'Dashboard Overview' })"
              >Lihat Web CV</c-menu-item
            > -->
          <c-menu-item as="a" :href="feURL + '/logout'" @click="logout()"
            >Keluar</c-menu-item
          >
        </c-menu-list>
      </c-menu>
    </CFlex>
    <!-- </Cbox> -->
  </CBox>
</template>

<script>
// import { GET_USER } from "@/store/auth.module";
import { PURGE_AUTH } from "@/store/auth.module";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  CBox,
  CFlex,
  CStack,
  CMenu,
  CMenuButton,
  CMenuList,
  CMenuItem,
  CButton,
  CImage,
  CText,
  CAvatar,
} from "@chakra-ui/vue";
import config from "@/ConfigProvider";

export default {
  name: "HeaderDashboard",
  components: {
    CBox,
    CFlex,
    CStack,
    CMenu,
    CMenuButton,
    CMenuList,
    CMenuItem,
    CButton,
    CImage,
    CText,
    CAvatar,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      feURL: config.value("GETHIRED_WEB_URL"),
      blogUrl: config.value("DEVCODE_BLOG_URL"),
      urlGethired: config.value("GETHIRED_WEB_URL"),
      cdnGethiredUrl: config.value("GETHIRED_CDN_URL"),
      // webcvURL: config.value('WEBCV_URL')
    };
  },
  mounted() {
    // this.getData();
    // this.$store.dispatch(GET_USER)
  },
  computed: {
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(PURGE_AUTH);
      window.location.href = this.feURL;
    },
    // async getData() {
    //   // if (!this.isAuthenticated) {
    //   // this.isLoading = true;
    //   await this.$store.dispatch(GET_JWT);
    //   // }
    //   setTimeout(async () => {
    //     await this.$store.dispatch(GET_USER);
    //     // this.isLoading = false;
    //   }, 100);
    // },
  },
};
</script>
