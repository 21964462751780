var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',{attrs:{"bg":"main","h":"54px"}},[_c('Loading',{attrs:{"active":_vm.isLoading}}),_c('CFlex',{attrs:{"pt":"5px","pb":"5px","alignItems":"center","justifyContent":"space-between","maxW":"1200px","mx":"auto","d":['none', 'none', 'none', 'flex']}},[_c('CFlex',[(_vm.isAuthenticated)?_c('a',{staticStyle:{"vertical-align":"middle"},attrs:{"href":"https://gethired.id/employee/welcome"}},[_c('img',{attrs:{"src":require("@/assets/img/logo.svg")}})]):_c('a',{staticStyle:{"vertical-align":"middle"},attrs:{"href":"https://gethired.id"}},[_c('img',{attrs:{"src":require("@/assets/img/logo.svg")}})])]),_c('CStack',{attrs:{"display":"flex","direction":"row","spacing":6}},[_c('CButton',{attrs:{"bg":"bgCodeCompetitionBtn","color":"white","_hover":{ bg: 'bgCodeCompetitionBtn' },"as":"a","href":_vm.blogUrl}},[_c('CImage',{attrs:{"src":require('@/assets/icon/ic-devcode.png'),"pr":"5px"}}),_vm._v(" DEVCODE ")],1),_c('CButton',{attrs:{"bg":"orange","color":"white","_hover":{ bg: 'bgCodeCompetitionBtn' },"as":"a","href":`${_vm.urlGethired}/jobs`}},[_vm._v(" CARI KERJA ")]),_c('c-menu',[_c('c-menu-button',{attrs:{"bg":"main","_hover":{ bg: 'main' },"border-width":"0px","boxShadow":"0px","_focus":{
            outline: 'none',
            boxShadow: 'none',
          },"right-icon":"chevron-down","color":"white"}},[_c('CAvatar',{attrs:{"w":"35px","h":"35px","mr":"5px","name":_vm.currentUser.shortName,"src":_vm.cdnGethiredUrl + '/' + _vm.currentUser.emp_photo}}),_c('CText',{attrs:{"fontWeight":"bold"}},[_vm._v("Hi, "+_vm._s(_vm.currentUser.shortName))])],1),_c('c-menu-list',{attrs:{"zIndex":"99"}},[_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/employee/profile'}},[_vm._v("Lihat Profile")]),_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/employee/setting'}},[_vm._v("Ubah Password")]),_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/employee/cv'}},[_vm._v("Pilih Desain CV")]),_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/employee/application'}},[_vm._v("Lamaran Saya")]),_c('c-menu-item',{attrs:{"as":"a","href":"/clubs"}},[_vm._v("Clubs")]),_c('c-menu-item',{attrs:{"as":"a","href":"/track-career-all"}},[_vm._v("Track")]),_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/logout'},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Keluar")])],1)],1)],1)],1),_c('CFlex',{attrs:{"pt":"5px","pb":"5px","alignItems":"center","justifyContent":"space-between","maxW":"1080px","mx":"auto","d":['flex', 'flex', 'flex', 'none']}},[_c('CFlex',[(_vm.isAuthenticated)?_c('a',{staticStyle:{"vertical-align":"middle"},attrs:{"href":"https://gethired.id/employee/welcome"}},[_c('CImage',{attrs:{"src":require('@/assets/img/logo.svg'),"w":['100px', '150px', '170px', '100%'],"ml":['1em', '1em', '1em', '0']}})],1):_c('a',{staticStyle:{"vertical-align":"middle"},attrs:{"href":"https://gethired.id"}},[_c('CImage',{attrs:{"src":require('@/assets/img/logo.svg'),"w":['100px', '150px', '170px', '100%'],"ml":['1em', '1em', '1em', '0']}})],1)]),_c('c-menu',[_c('c-menu-button',{attrs:{"bg":"main","_hover":{ bg: 'main' },"border-width":"0px","boxShadow":"0px","_focus":{
          outline: 'none',
          boxShadow: 'none',
        },"right-icon":"chevron-down","color":"white"}},[_c('CAvatar',{attrs:{"w":"35px","h":"35px","mr":"5px","name":_vm.currentUser.shortName,"src":_vm.cdnGethiredUrl + '/' + _vm.currentUser.emp_photo}}),_c('CText',{attrs:{"fontWeight":"bold"}},[_vm._v("Hi, "+_vm._s(_vm.currentUser.shortName))])],1),_c('c-menu-list',{attrs:{"zIndex":"99"}},[_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/employee/profile'}},[_vm._v("Lihat Profile")]),_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/employee/setting'}},[_vm._v("Ubah Password")]),_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/employee/cv'}},[_vm._v("Pilih Desain CV")]),_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/employee/setting'}},[_vm._v("Cari Kerja")]),_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/employee/application'}},[_vm._v("Lamaran Saya")]),_c('c-menu-item',{attrs:{"as":"a","href":"/clubs"}},[_vm._v("Clubs")]),_c('c-menu-item',{attrs:{"as":"a","href":"/track-career-all"}},[_vm._v("Track")]),_c('c-menu-item',{attrs:{"as":"a","href":_vm.feURL + '/logout'},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Keluar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }